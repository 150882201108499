export const useBlogStore = defineStore('blog', {
  state: () => ({
    tags: [],
    post: null,
    list: [],
  }),
  getters: {},
  actions: {
    SET_BLOG_LIST (list) {
      this.list = [ ...list ]
    },

    SET_BLOG_TAGS (tags) {
      this.tags = [ ...tags ]
    },

    SET_BLOG_POST (post) {
      this.post = post
    },

    async fetchBlogList () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/blog/list`)

        const allTags = result.flatMap(item => item.tags)
        const uniqueTags = [...new Set(allTags)]

        this.SET_BLOG_TAGS(uniqueTags)

        this.SET_BLOG_LIST(result)

        return result
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('Blog: Error fetching blog list', {
          level: 'warning',
          contexts: {
            details: err.data || err,
          },
          tags: {
            type: 'UX',
          },
        })
        return null
      }
    },

    async fetchBlogPost (url) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: post } = await apiFetch(`/${locale}/blog/get/${url}`)

        this.SET_BLOG_POST(post)

        return post
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('Blog: Error fetching blog post', {
          level: 'warning',
          contexts: {
            details: err.data || err,
          },
          tags: {
            type: 'UX',
          },
        })
        return null
      }
    },

    async fetchBlogTag (tag) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: list } = await apiFetch(`/${locale}/blog/list?tag=${tag}`)

        return list
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('Blog: Error fetching blog list by tag', {
          level: 'warning',
          contexts: {
            details: err.data || err,
          },
          tags: {
            type: 'UX',
          },
        })
        return null
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBlogStore, import.meta.hot))
}
